/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/06/2021.
 */
import EventsStrategy from "./EventsStrategy";
import { RRule, RRuleSet } from "rrule";
import { lcFirst } from "../../helpers/string";
import { cleanRRule, toRRuleInput } from "../../helpers/calendar";
import { getLinksFromDescription } from "../../helpers/widgetItems";
import AxiosConfig from "../../AxiosConfig";

const sanitizeValue = (value, defaultValue) => {
  return value && !Array.isArray(value) ? value : defaultValue;
};
const utcOffset = new Date().getTimezoneOffset();

/**
 *
 * @param Number startDate
 * @param String recurrenceDesc
 * @returns {string|null}
 */
const parseRecurrenceSeries = (startDate, recurrenceDesc, exceptions) => {
  try {
    if (recurrenceDesc && recurrenceDesc.match(/every/i)) {
      const sanitized = recurrenceDesc
        // remove `from` string- it contains times that are redundant
        .replace(/from .*/, "")
        // remove `the` as rrule library does not support it
        .replaceAll(/the /gi, "")
        // replace `and` with comma
        .replaceAll(/ and /gi, ", ")
        // replace `of month` `of week` and replace it with comma
        .replaceAll(/of .*,/gi, ", ")
        // replace ordinals to short version of them
        .replaceAll(/first/gi, "1st")
        .replaceAll(/second/gi, "2nd")
        .replaceAll(/third/gi, "3rd")
        .replaceAll(/fourth/gi, "4th")
        .replace(/, is an .*/, "")
        .replace(/, is an .*/, "")
        .replace(/([a-zA-Z]{3})(\.)/, "$1")
        .trim();

      const options = RRule.parseText(sanitized);

      options.dtstart = new Date(startDate * 1000);

      const rule = cleanRRule(options);

      const ruleSet = new RRuleSet();
      if (!rule) {
        console.warn(
          "Rule is null for startDate: ",
          startDate,
          "; ",
          sanitized
        );
        return [null, null];
      }
      ruleSet.rrule(rule);

      if (exceptions) {
        // cast exceptions to array if it's an object
        const exceptionsArray = Array.isArray(exceptions) ? exceptions : [];

        if (typeof exceptions === "object") {
          Object.keys(exceptions).forEach((key) => {
            exceptionsArray.push(exceptions[key]);
          });
        }

        const exceptionDates = exceptionsArray.map((i) => {
          const exDateMilliseconds =
            (new Date(i).getTime() + utcOffset * 60 * 1000) * 1;
          const exDate = new Date(exDateMilliseconds);
          const dstDiff = exDate.getTimezoneOffset() - utcOffset;
          exDate.setHours(options.dtstart.getHours());
          exDate.setMinutes(options.dtstart.getMinutes());
          exDate.setMinutes(exDate.getMinutes() - dstDiff);

          ruleSet.exdate(exDate);
        });

        rule.exdate = exceptionDates;
      }

      if (ruleSet) {
        return [ruleSet.toString(), ruleSet.rrules()[0].toText()];
      }
    }
  } catch (e) {
    console.error(e);
  }

  return [null, null];
};

class CCBEventsStrategy extends EventsStrategy {
  processData(data, integration) {
    return data.map((i) => {
      const data = i.data;
      const eventProfile = i.event_profile.data;

      const isPrivate = eventProfile.public_calendar_listed !== "true";
      // get subdomain from integration url
      const subdomain = integration.address.match(
        /https:\/\/(.*)\.ccbchurch.com/
      )[1];

      let forms = null;

      if (
        eventProfile.registration &&
        eventProfile.registration.forms &&
        eventProfile.registration.forms.registration_form
      ) {
        const registrationForm =
          eventProfile.registration.forms.registration_form;

        if (Array.isArray(registrationForm) && registrationForm.length) {
          forms = registrationForm.map((i) => {
            return {
              ...i,
              id: i["@id"],
            };
          });
        } else if (
          typeof registrationForm === "object" &&
          registrationForm !== null
        ) {
          forms = [
            {
              ...registrationForm,
              id: registrationForm["@id"],
            },
          ];
        }
      }

      const recurrenceDesc = lcFirst(
        sanitizeValue(eventProfile.recurrence_description, "")
      );

      const recurringDate = new Date(recurrenceDesc.replace("at ", ""));
      const hasValidRecurringDate =
        recurringDate instanceof Date && !isNaN(recurringDate);

      const startDate = i.start_date; /* moment(i.start_date * 1000)
        //.add(utcOffset, "m")
        .unix();*/

      let [recurrence_series, recurrence_description] = [null, null];

      try {
        [recurrence_series, recurrence_description] = parseRecurrenceSeries(
          startDate,
          recurrenceDesc,
          eventProfile.exceptions
        );
      } catch (e) {
        console.error("Could not parse recurrence series: ", e);
      }
      const description = sanitizeValue(eventProfile.description, "");

      const organizer = {
        id: "",
        name: sanitizeValue(data.leader_name["$"], null),
        email: sanitizeValue(data.leader_email),
        phone: sanitizeValue(data.leader_phone, null),
        notes: sanitizeValue(eventProfile.leader_notes, null),
      };

      const isAllDayEvent = sanitizeValue(eventProfile.end_datetime, "").match(
        /^.*23\:59\:[0-9]{2}$/
      )
        ? true
        : false;

      const descriptionLinks = getLinksFromDescription(description);

      const startDateObj = new Date(startDate * 1000);
      // startDateParamFormat needs to be in format 20241201 based on startDateObj
      const startDateParamFormat = `${startDateObj.getFullYear()}${(
        "0" +
        (startDateObj.getMonth() + 1)
      ).slice(-2)}${("0" + startDateObj.getDate()).slice(-2)}`;

      const ctas = {
        linkFirst: descriptionLinks[0] || null,
        linkLast: descriptionLinks[1] || null,
        eventPage: !isPrivate
          ? {
              url: `https://${subdomain}.ccbchurch.com/goto/events/public/${i.platform_event_id}/${startDateParamFormat}`,
              label: "Learn more",
            }
          : null,

        organizer:
          organizer && organizer.email
            ? {
                url: `mailto:${organizer.email}`,
                label: "Contact leader",
              }
            : null,

        form: forms ? { url: forms[0].url, label: forms[0].name } : null,
      };

      // location can be a string or an empty array, empty array or unset means no location
      const hasLocation = sanitizeValue(data.location, null) ? true : false;

      const coverImagePath = sanitizeValue(
        i.image_path || eventProfile.image,
        null
      );
      const coverImage = coverImagePath
        ? coverImagePath.includes("http")
          ? coverImagePath
          : AxiosConfig.getEndpointAddress() + `/${coverImagePath}`
        : null;

      return {
        created: eventProfile.created,
        created_by: eventProfile.creator["$"],
        start_time: startDate,
        end_time: i.end_date /*moment(i.end_date * 1000)
          //.add(utcOffset, "m")
          .unix(),*/,
        timezone: eventProfile.timezone,
        recurrence_description: recurrence_description,
        recurrence_series,
        allDay: isAllDayEvent,
        duration: data.event_duration,
        name: eventProfile.name,
        description,
        id: i.id,
        ics_id: i.platform_event_id,
        modified: eventProfile.modified,
        modified_by: eventProfile.modifier["$"],
        integration_event_id: i.platform_event_id,
        slug: i.short_slug,
        display_location: hasLocation
          ? sanitizeValue(
              eventProfile.location && eventProfile.location.name,
              null
            )
          : null,
        cover_image: coverImage,
        is_private: isPrivate,
        location: hasLocation
          ? {
              city: sanitizeValue(eventProfile.location.city, null),
              line_1: sanitizeValue(eventProfile.location.line_1, null),
              line_2: sanitizeValue(eventProfile.location.line_2, null),
              name: sanitizeValue(eventProfile.location.name, null),
              zip: sanitizeValue(eventProfile.location.zip, null),
              street_address: sanitizeValue(
                eventProfile.location.street_address,
                null
              ),
            }
          : {},
        organizer,
        contact: {
          phone: "",
        },
        campus: {},
        rsvp: {
          type: eventProfile.registration.event_type["$"],
          type_id: eventProfile.registration.event_type["@id"],
          description: data.event_type,
          limit: eventProfile.registration.limit,
        },
        forms,
        ctas,
        accentColor: null,
        is_featured: i.is_featured,
      };
    });
  }
}

export default CCBEventsStrategy;
