/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/08/2021.
 */
import EventsStrategy from "./EventsStrategy";
import AxiosConfig from "../../AxiosConfig";
import { RRule, RRuleSet } from "rrule";
import { lcFirst } from "../../helpers/string";
import { cleanRRule } from "../../helpers/calendar";
import { getLinksFromDescription, linkify } from "../../helpers/widgetItems";

const sanitizeValue = (value, defaultValue) => {
  return value && !Array.isArray(value) ? value : defaultValue;
};

/**
 *
 * @param rrule
 * @returns {Date[]|null}
 */
const parseRecurrenceSeries = (startDate, rrule) => {
  if (rrule) {
    try {
      const rruleSet = new RRuleSet();

      const options = RRule.parseString(rrule);

      options.dtstart = new Date(startDate * 1000);
      // options.dtstart = new Date(startDate * 1000);

      const cleanRule = cleanRRule(options);

      cleanRule.options.dtstart = cleanRule.options.dtstart;
      rruleSet.rrule(cleanRule);
      return [rruleSet.toString(), cleanRule.toText()];
    } catch (e) {
      console.error(e);
    }
  }

  return [null, null];
};

class GoogleEventsStrategy extends EventsStrategy {
  processData(data) {
    return data.map((i) => {
      const event = i.data;

      const startDate = i.start_date;
      const endDate = i.end_date;

      const eventColor = i.color?.data || null;

      const coverImagePath =
        i.attachments && i.attachments.length
          ? i.attachments[0].data.path
          : null;

      const coverImage = coverImagePath
        ? coverImagePath.includes("http")
          ? coverImagePath
          : AxiosConfig.getEndpointAddress() + `/${coverImagePath}`
        : null;

      const oldRule = event?.recurrence?.join("\n");
      const rruleFromEventProfile =
        i?.event_profile?.data?.recurrence?.join("\n");
      const rrule = rruleFromEventProfile || oldRule;
      // event.recurrence && event.recurrence.length
      //   ? /* rrulestr(*/ event.recurrence.join("\n")
      //   : null;

      const [recurrence_series, rruleDesc] = parseRecurrenceSeries(
        startDate,
        rrule
      );

      const isAllDayEvent = sanitizeValue(event.start.date, null)
        ? true
        : false;

      const description = linkify(
        event.description || "" //.replaceAll("</", "\n</") // TODO verify
      );

      const descriptionLinks = getLinksFromDescription(description);

      const organizer = {
        name: sanitizeValue(
          event.organizer.displayName
            ? event.organizer.displayName
            : event.organizer.email,
          null
        ),
        email: sanitizeValue(event.organizer.email, null),
      };

      const ctas = {
        linkFirst: descriptionLinks[0] || null,
        linkLast: descriptionLinks[1] || null,
        eventPage: null,
        form: null,
        organizer:
          organizer && organizer.email
            ? {
                url: `mailto:${organizer.email}`,
                label: "Contact organizer",
              }
            : null,
      };

      const eventPlace = i?.place?.data || null;

      const place = eventPlace
        ? {
            displayName: eventPlace.displayName?.text || "",
            summary: eventPlace.editorialSummary?.text || "",
            iconBgColor: eventPlace.iconBackgroundColor,
            iconURi: eventPlace.iconMaskBaseUri,
            rating: eventPlace.rating,
            userRatingCount: eventPlace.userRatingCount,
          }
        : null;

      if (place && eventPlace?.photos) {
        place.photos = eventPlace.photos.map((photo) => {
          return {
            url: photo?.path.includes("http")
              ? photo.path
              : AxiosConfig.getEndpointAddress() + `/${photo.path}`,
            caption: photo?.authorAttributions[0]?.displayName,
          };
        });
      }

      return {
        id: i.id,
        ics_id: i.platform_event_id,
        slug: i.short_slug,
        description,
        name: event.summary,
        //created: moment(attributes.created_at).unix(),
        //modified: moment(attributes.updated_at).unix(),
        //created_by: eventProfile.creator["$"],
        start_time: startDate,
        end_time: endDate,
        //timezone: eventProfile.timezone, todo
        allDay: isAllDayEvent,
        timezone: "UTC", //event.start.timeZone || i.calendar.data.timeZone || "UTC",
        recurrence_description: rruleDesc ? lcFirst(rruleDesc) : null,
        recurrence_series,
        //duration: data.event_duration,
        //modified_by: eventProfile.modifier["$"],
        //integration_event_id: i.platform_event_id,
        display_location: event.location,
        cover_image: coverImage,
        //is_private: eventProfile.public_calendar_listed !== "true",
        location: {
          city: " ",
          /*  line_1: sanitizeValue(eventProfile.location.line_1, null),
            line_2: sanitizeValue(eventProfile.location.line_2, null),*/
          name: event.location,
          /*zip: sanitizeValue(eventProfile.location.zip, null),
          street_address: sanitizeValue(
            eventProfile.location.street_address,
            null
          ),*/
        },
        organizer,
        contact: {
          phone: "",
        },
        accentColor: eventColor
          ? eventColor.background
          : i.calendar.data.backgroundColor,
        fontColor: eventColor
          ? eventColor.foreground
          : i.calendar.data.foregroundColor,
        ctas,
        place,
        is_featured: i.is_featured,
      };
    });
  }
}

export default GoogleEventsStrategy;
