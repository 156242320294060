/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/02/2023.
 */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { dceDebug } from "../../helpers/debug";
import IntegrationContext, {
  getStrategy,
} from "./strategies/IntegrationContext";
import { useAlertStore } from "../../hooks/redux/alert";
import { useAlertEventsStore } from "../../hooks/redux/alertEvents";
import Alert from "./components/Alert";
import { useOptionValue } from "../../builder";
import useOnScrollPage from "../../hooks/useOnScrollPage";
import { useAlertEmbeddedStore } from "../../hooks/redux/alertEmbedded";

/**
 *
 * @param showAfter
 * @param showAgainAfter
 * @param lastClosedValue
 * @param lastEventSlugValue
 * @param eventsCollection
 * @returns {boolean}
 */
const checkTimingCriteria = (
  showAfter,
  showAgainAfter,
  lastClosedValue,
  lastEventSlugValue,
  eventsCollection
) => {
  const showAgainAfterValue = Number.parseInt(showAgainAfter);

  if (!showAgainAfter) {
    return false;
  }

  const lastClosed = lastClosedValue ? new Date(lastClosedValue) : null;

  if (lastClosed) {
    if (showAgainAfter === "reload") {
      return true;
    }

    if (showAgainAfter === "never") {
      return false;
    }

    if (showAgainAfter === "event") {
      if (!eventsCollection.length) {
        return false;
      }

      const currentEvent = eventsCollection[0];

      if (lastEventSlugValue !== currentEvent.slug) {
        return true;
      } else {
        return false;
      }
    }

    const timeDiff = new Date() - lastClosed;

    // showAgainAfter is in days, it needs to be converted to ms
    const showAgainAfterMs = showAgainAfterValue * 24 * 60 * 60 * 1000;

    if (timeDiff < showAgainAfterMs) {
      return false;
    }
    return true;
  } else {
    return true;
  }
};

const checkPageCriteria = (
  showOnPage,
  showOnPageOption,
  showOnSpecificPage
) => {
  // hostname without www. prefix if exist
  const hostname = window.location.hostname.replace(/^www\./, "");

  if (!showOnPage || showOnPage.toLowerCase() !== hostname) {
    return false;
  }

  if (showOnPageOption === "*" || showOnPageOption === "") {
    return true;
  } else if (showOnPageOption === "/" && window.location.pathname === "/") {
    return true;
  }
  if (showOnPageOption === "custom") {
    let path = showOnSpecificPage.trim();

    if (path.charAt(path.length - 1) === "/") {
      path = path.slice(0, -1);
    }
    const pattern = new RegExp(`^/${path}/?$`);
    if (window.location.pathname.match(pattern)) {
      return true;
    }
  }

  return false;
};

const RouterClass = (props) => {
  const [definitionsLoaded, setDefinitionsLoaded] = useState(false);
  const { alert, setAlert, showAlert, hideAlert } = useAlertStore();
  const { alertEmbedded } = useAlertEmbeddedStore();
  const { alertData, node } = props;
  const { events } = useAlertEventsStore();

  const showAfter = useOptionValue(alert.model, "dcaShowAfter");
  const showAgainAfter = useOptionValue(alert.model, "dcaShowAgainAfter");

  const showOnPage = useOptionValue(
    alert.model,
    "dcAPageToDisplay",
    null,
    "behavior"
  );
  const showOnPageOption = useOptionValue(
    alert.model,
    "dcAPageToShowOnOption",
    null,
    "behavior"
  );
  const showOnSpecificPage = useOptionValue(
    alert.model,
    "dcAPageToShowOn",
    null,
    "behavior"
  );

  dceDebug(showOnPage, showOnPageOption, showOnSpecificPage);
  const canShowAlert = useMemo(() => {
    const timingCriteria = checkTimingCriteria(
      showAfter,
      showAgainAfter,
      alertEmbedded.lastClosed,
      alertEmbedded.lastEventSlug,
      events.collection
    );

    // console.log(showOnPage, showOnPageOption, showOnSpecificPage);
    const pageCriteria = checkPageCriteria(
      showOnPage,
      showOnPageOption,
      showOnSpecificPage
    );
    // console.log(timingCriteria, pageCriteria);

    return timingCriteria && pageCriteria;
  }, [
    showAfter,
    showAgainAfter,
    alertEmbedded.lastClosed,
    alertEmbedded.lastEventSlug,
    events.collection,
    showOnPage,
    showOnPageOption,
    showOnSpecificPage,
    events.fetchSuccess,
  ]);

  /**
   *
   * @type {(function(): void)|*}
   */
  const onShowAlertOnScroll = useCallback(() => {
    if (canShowAlert) {
      showAlert();
    }
  }, [canShowAlert]);

  useOnScrollPage({
    initialized: definitionsLoaded && events.fetchSuccess,
    callback: onShowAlertOnScroll,
    when: showAfter,
    deps: [canShowAlert],
  });

  useEffect(() => {
    //fetchAlert(props.widgetUUID);
    //hideAlert();
    node.classList.add("g" + alertData.uuid);
    node.classList.add("dce--container-" + alertData.template);
    node.classList.add("alerts-background");
    node.setAttribute("id", alertData.uuid);
    setAlert(alertData);
  }, []);

  useEffect(() => {
    try {
      window.updateStylesheet(alertData);
    } catch (e) {
      console.warn(
        `ALERTS: Check if you have multiple <script id="dce-embeddable-script"/> on your page [${alert.uuid}]`
      );
      console.log(e);
    }
    setDefinitionsLoaded(true);
  }, [alert.fetchSuccess]);

  useEffect(() => {
    // console.log(
    //   "useEffect",
    //   definitionsLoaded,
    //   events.fetchSuccess,
    //   canShowAlert
    // );
    if (definitionsLoaded && events.fetchSuccess && canShowAlert) {
      // how alert after a timeout, if there is any specified
      const showAfterValue = Number.parseInt(showAfter);

      if (Number.isInteger(showAfterValue)) {
        setTimeout(showAlert, showAfterValue * 1000);
      }
    }
  }, [definitionsLoaded, events.fetchSuccess, canShowAlert]);

  /*if (!isValidId) {
    return (
      <UnreachableError id={props.widgetUUID} widgetName={"alerts widget"} />
    );
  }
*/
  //const alertLoading = (!alert.model && alert.fetch) || !definitionsLoaded;

  dceDebug(props);

  /*if (alert.corsError) {
    window.dceWidgetInit = false;

    return <CorsError id={props.widgetUUID} widgetName={"alerts widget"} />;
  } else if (alert.fetchError) {
    window.dceWidgetInit = false;
    if (alert.unpublished) {
      return (
        <NotPublishedError id={props.widgetUUID} widgetName={"alerts widget"} />
      );
    }
    return (
      <UnreachableError id={props.widgetUUID} widgetName={"alerts widget"} />
    );
  }*/

  if (!alert.model || alert.fetch) {
    return null;
    /*    <div className="calendar">
        <LoadingIndicator />
      </div>*/
  } else if (!alert.model && !alert.fetch) {
    /*  console.log("Unable to load calendar data.", alert.model, alert.fetchError);
    window.dceWidgetInit = false;

    return (
      <UnreachableError id={props.widgetUUID} widgetName={"alerts widget"} />
    );*/
  }

  return (
    <IntegrationContext.Provider
      value={getStrategy(alert.model.integration.type, alert.model)}
    >
      <Alert alert={alert} widgetUUID={alertData.uuid} embedded={true} />
    </IntegrationContext.Provider>
  );
};

export default RouterClass;
