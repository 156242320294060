/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/12/2021.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as appActions from "../../actions/main";

export function useAppStore() {
  const dispatch = useDispatch();
  const _app = useSelector((store) => store.main);
  const _dismissedAlerts = useSelector((store) => store.dismissedAlerts);

  const clearErrors = useCallback(
    async () => await dispatch(appActions.clearErrors()),
    [dispatch]
  );

  const initializeIntercom = useCallback(
    async (user, organization, checkMobile) =>
      await dispatch(
        appActions.initializeIntercom(user, organization, checkMobile)
      ),
    [dispatch]
  );

  const updateIntercom = useCallback(
    async (user, organization, checkMobile) =>
      await dispatch(
        appActions.updateIntercom(user, organization, checkMobile)
      ),
    [dispatch]
  );

  const shutdownIntercom = useCallback(
    async (user) => await dispatch(appActions.shutdownIntercom(user)),
    [dispatch]
  );

  const changePreviewType = useCallback(
    async (previewType) =>
      await dispatch(appActions.changePreviewType(previewType)),
    [dispatch]
  );

  const toggleWidgetPreview = useCallback(
    async (isOn) => await dispatch(appActions.toggleWidgetPreview(isOn)),
    [dispatch]
  );

  const toggleAdminNavigation = useCallback(
    async () => await dispatch(appActions.toggleAdminNavigation()),
    [dispatch]
  );

  const dismissAlert = useCallback(
    (id) => dispatch(appActions.dismissAlert(id)),
    [dispatch]
  );

  return {
    app: _app,
    dismissedAlerts: _dismissedAlerts,
    clearErrors,
    initializeIntercom,
    updateIntercom,
    shutdownIntercom,
    changePreviewType,
    toggleWidgetPreview,
    toggleAdminNavigation,
    dismissAlert,
  };
}
