import { combineReducers } from "redux";

import main from "./main";
import alert from "./alert";
import alertEvents from "./alertEvents";
import alertEmbedded from "./alertEmbedded";

const createRootReducer = (history) =>
  combineReducers({
    main,
    alert,
    alertEvents,
    alertEmbedded,
  });

export default createRootReducer;
